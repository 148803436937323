import { initGlobalState } from 'qiankun';
const state = {
  abc: 123
}
const actions = initGlobalState(state);

//监听通讯池的变化
actions.onGlobalStateChange((state, prev) => {
  //state变更后的状态 prev变更前的状态
  // console.log(state, prev, 'state  prev');
})
const getActiveRule = (hash) => (location) => location.hash.startsWith(hash);
const microParams = [
  {
    name: 'dataManager', //子应用的项目名称
    // entry:'http://192.168.1.49:8081', //子应用的链接
    entry: `${process.env.NODE_ENV === "development" ? 'http://' + location.hostname + ':8081' : '/freexserver/microApps/datamanager/index.html'}`, //子应用的链接
    // entry:'/security/microApps/datamanager/index.html', 
    container: '#app', //子应用的节点容器（vue一般是#app）
    activeRule: getActiveRule('#/datamanager'), //访问子应用的规则，eg：主应用的地址是localhost:8080,那么访问子应用的地址就是localhost:8080/datamanager
    // activeRule: '/datamanager', //访问子应用的规则，eg：主应用的地址是localhost:8080,那么访问子应用的地址就是localhost:8080/datamanager
    props: {
      routerBase: '/datamanager',
    }
  },
  {
    name: 'dataServer',
    entry: `${process.env.NODE_ENV === "development" ? 'http://' + location.hostname + ':8082' : '/freexserver/microApps/dataserver/index.html'}`,
    // entry:'http://192.168.1.49:8082',
    // entry:'/security/microApps/dataserver/index.html',
    container: '#app',
    activeRule: getActiveRule('#/dataserver'),
    props: {
      routerBase: '/dataserver',
    }
  },
  {
    name: 'dataFactory',
    entry: `${process.env.NODE_ENV === "development" ? 'http://' + location.hostname + ':8083' : '/freexserver/microApps/datafactory/index.html'}`,
    container: '#app',
    activeRule: getActiveRule('#/datafactory'),
    props: {
      routerBase: '/datafactory',
    }
  },
  // {
  //   name: 'cloudSimulation',
  //   entry: `${process.env.NODE_ENV === "development" ? 'http://' + location.hostname + ':8086' : '/freexserver/microApps/cloudSimulation/index.html'}`,
  //   container: '#app',
  //   activeRule: getActiveRule('#/cloudSimulation'),
  //   props: {
  //     routerBase: '/cloudSimulation'
  //   }
  // },
  {
    name: 'mapEdit',
    entry: `${process.env.NODE_ENV === "development" ? 'http://' + location.hostname + ':8084' : '/freexserver/microApps/mapEdit/index.html'}`,
    // entry:'http://192.168.1.49:8082',
    // entry:'/security/microApps/dataserver/index.html',
    container: '#app',
    activeRule: getActiveRule('#/mapEdit'),
    props: {
      routerBase: '/mapEdit',
      mainAppRouter: actions
    }
  },
]

export {
  microParams
}