import {
    get,
    post
} from './axios';

//是否注册
export const system = () => get('/freexserver/query/system/user');

//是否登录
export const isLogin = () => get("/freexserver/user/queryCurrentUser");

//修改密码
export const changePass = (params) => post("/freexserver/dataserver/user/modifypassword", params);

//退出登录
export const logout = () => get("/freexserver/logout");

//登录
export const login = (userName, passWord, isRemember) => get(`/freexserver/dologin?userName=${userName}&passWord=${passWord}&rememberme=${isRemember}`);

//校验原密码
export const oldPass = (name,oldPass) => get(`/freexserver/dataserver/user/check/${name}/${oldPass}`);

// 文件服务器校验
export const checkFileServerPath = (params) => get('/freexserver/dataserver/dataserver/check/path?filePath=' + params);

// 注册服务
export const registerServer = (params) => post(`/freexserver/datamanager/config/registerServer`,params);

//配置成功接口
export const datamanagerAPI = () => get('/freexserver/datamanager/config/queryConfigStorage');

//初始化
export const initialize = (userName, passWord) => get(`/freexserver/dataserver/save/init/${userName}/${passWord}?fileServer=LocalHost`);

//当用户被锁定时候的接口
export const lock = (userName) => get(`/freexserver/dataserver/user/lockUser/${userName}`);

// 查询mac码
export const getMacCode = () => get('/freexserver/dataserver/query/sysmac');

// 激活
export const activeSystime = (params,data) => get('/freexserver/dataserver/active/systime/' + params, data);

// 查询是否到期
export const getRemainTime = () => get('/freexserver/dataserver/check/systime');